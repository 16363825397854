// frontend/src/theme.js

const baseTheme = {
  transition: 'all 0.3s ease',
  borderRadius: '8px',
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
};

export const themes = {
  midnightBlue: {
    ...baseTheme,
    name: 'Midnight Blue',
    mode: 'dark',
    background: '#0A1929',
    cardBackground: '#132F4C',
    cardBackgroundHover: '#1E3A5F',
    primary: '#5090D3',
    secondary: '#B2BAC2',
    accent1: '#FFD700',
    accent2: '#4DD0E1',
    accent3: '#9C27B0',
    text: '#FFFFFF',
    textLight: '#F0F0F0',
    textSecondary: '#D0D7DE',
    textDark: '#0A1929',
    error: '#FF6B6B',
    success: '#4CAF50',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    chartColors: ['#FFD700', '#4DD0E1', '#9C27B0', '#FF6B6B', '#4CAF50'],
    recommendationBorder: '2px solid #5090D3',
    recommendationBackground: 'rgba(80, 144, 211, 0.1)',
  },
  twilightPurple: {
    ...baseTheme,
    name: 'Twilight Purple',
    mode: 'dark',
    background: '#2E0854',
    cardBackground: '#3C096C',
    cardBackgroundHover: '#4C0B5F',
    primary: '#FF00FF',
    secondary: '#DA70D6',
    accent1: '#FF69B4',
    accent2: '#FFB6C1',
    accent3: '#FFA07A',
    text: '#FFFFFF',
    textLight: '#F0E6FF',
    textSecondary: '#D8BFD8',
    textDark: '#E6E6FA',
    error: '#FF1493',
    success: '#00FA9A',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    chartColors: ['#FF69B4', '#FFB6C1', '#FFA07A', '#FF1493', '#00FA9A'],
    recommendationBorder: '2px solid #FF00FF',
    recommendationBackground: 'rgba(255, 0, 255, 0.1)',
  },
  cosmicNight: {
    ...baseTheme,
    name: 'Cosmic Night',
    mode: 'dark',
    background: '#1A1A2E',
    cardBackground: '#16213E',
    cardBackgroundHover: '#0F3460',
    primary: '#E94560',
    secondary: '#533483',
    accent1: '#FFD700',
    accent2: '#00CED1',
    accent3: '#FF69B4',
    text: '#FFFFFF',
    textLight: '#F0F0F0',
    textSecondary: '#B0C4DE',
    textDark: '#E0FFFF',
    error: '#FF6347',
    success: '#00FF7F',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    chartColors: ['#E94560', '#533483', '#FFD700', '#00CED1', '#FF69B4'],
    recommendationBorder: '2px solid #E94560',
    recommendationBackground: 'rgba(233, 69, 96, 0.1)',
  },
    sunriseGlow: {
    ...baseTheme,
    name: 'Sunrise Glow',
    mode: 'light',
    background: '#F7F3E3',
    cardBackground: '#FDF8E7',
    cardBackgroundHover: '#F5ECD5',
    primary: '#E67E22',
    secondary: '#F39C12',
    accent1: '#2980B9',
    accent2: '#16A085',
    accent3: '#C0392B',
    text: '#34495E',
    textLight: '#7F8C8D',
    textSecondary: '#95A5A6',
    textDark: '#2C3E50',
    error: '#E74C3C',
    success: '#27AE60',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    chartColors: ['#E67E22', '#F39C12', '#2980B9', '#16A085', '#C0392B'],
    recommendationBorder: '2px solid #E67E22',
    recommendationBackground: 'rgba(230, 126, 34, 0.1)',
  },
  springBreeze: {
    ...baseTheme,
    name: 'Spring Breeze',
    mode: 'light',
    background: '#E8F6F3',
    cardBackground: '#F0F9F6',
    cardBackgroundHover: '#D5F0E6',
    primary: '#27AE60',
    secondary: '#2ECC71',
    accent1: '#3498DB',
    accent2: '#E67E22',
    accent3: '#9B59B6',
    text: '#2C3E50',
    textLight: '#34495E',
    textSecondary: '#7F8C8D',
    textDark: '#1A2533',
    error: '#E74C3C',
    success: '#27AE60',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
    chartColors: ['#27AE60', '#2ECC71', '#3498DB', '#E67E22', '#9B59B6'],
    recommendationBorder: '2px solid #27AE60',
    recommendationBackground: 'rgba(39, 174, 96, 0.1)',
  },
};

export const validateTheme = (theme) => {
  const requiredProperties = [
    'name', 'mode', 'background', 'cardBackground', 'cardBackgroundHover',
    'primary', 'secondary', 'accent1', 'accent2', 'accent3',
    'text', 'textLight', 'textSecondary', 'textDark',
    'error', 'success', 'boxShadow', 'chartColors',
    'recommendationBorder', 'recommendationBackground'
  ];

  const missingProperties = requiredProperties.filter(prop => !(prop in theme));

  if (missingProperties.length > 0) {
    console.error(`Theme is missing required properties: ${missingProperties.join(', ')}`);
    return { ...themes.midnightBlue, ...theme }; // Fallback to midnightBlue for missing properties
  }

  return theme;
};

export default themes.midnightBlue;
