import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { UserPlus, ArrowLeft } from 'lucide-react';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const Form = styled(motion.form)`
  width: 100%;
  max-width: 400px;
  background-color: ${props => props.theme.cardBackground};
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const PasswordRequirement = styled.p`
  font-size: 0.8rem;
  color: ${props => props.theme.textSecondary};
  margin-top: 5px;
`;

const Button = styled(motion.button)`
  width: 100%;
  margin: 20px 0 10px;
  padding: 10px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const ErrorMessage = styled(motion.div)`
  color: ${props => props.theme.error};
  margin-bottom: 10px;
  text-align: center;
`;

const BackButton = styled(Link)`
  margin-top: 20px;
  color: ${props => props.theme.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [summonerName, setSummonerName] = useState('');
  const [tagline, setTagline] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        username,
        email,
        password,
        gameProfiles: {
          lol: `${summonerName}#${tagline}`
        }
      });
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'An error occurred during registration');
    }
  };

  return (
    <RegisterContainer>
      <Form
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title>Register</Title>
        {error && (
          <ErrorMessage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </ErrorMessage>
        )}
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PasswordRequirement>Password must be at least 8 characters long</PasswordRequirement>
        <Input
          type="text"
          placeholder="Summoner Name"
          value={summonerName}
          onChange={(e) => setSummonerName(e.target.value)}
          required
        />
        <Input
          type="text"
          placeholder="Tagline (e.g., NA1)"
          value={tagline}
          onChange={(e) => setTagline(e.target.value)}
          required
        />
        <Button
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <UserPlus size={18} />
          Register
        </Button>
      </Form>
      <BackButton to="/">
        <ArrowLeft size={18} />
        Back to Home
      </BackButton>
    </RegisterContainer>
  );
}

export default Register;
